import { useEffect } from "react";
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import { useReportUserNotesAnswersState, useUserState } from "context";
import { useHistory, useParams } from "react-router-dom";
import { usePopulateReportUserNotesAnswers } from "hooks/usePopulateReportUserNotesAnswers";
import { getAnswerKey } from "context/reportUserNotesAnswers";
import { ReportFields } from "actions/reportActions";
import { convertBoolToYesNo, dateFormatter } from "lib/commonFunctions";
import { isOrganizationTypeVesta } from "lib/permissions";
import { PermissionScope } from "actions/roleActions";
import classNames from "classnames";
import { LocationIcon, ExclamationGrayIcon, LocationGrayIcon, IconStyle } from "components/Icons";

interface fieldBlock {
  key: string;
  value?: Node | string;
  icon?: Node;

}

export const BasicReportInfo = () => {
  const userInfo = useUserState();
  const reportUserNotesAnswers = useReportUserNotesAnswersState();
  const populateReportUserNotesAnswers = usePopulateReportUserNotesAnswers();
  const { reportId } = useParams<{ reportId: string }>();
  const history: any = useHistory();
  
  useEffect(() => {
    if (reportId) {
      if ( !reportUserNotesAnswers.report || (reportUserNotesAnswers?.report?.id && Number(reportId) !== reportUserNotesAnswers.report.id) ) {
        const populateContext = async () => {
          try {
            await populateReportUserNotesAnswers(reportId, history.location?.state?.getNotes || true, true);
          } catch (err) {
            // TODO - https://trello.com/c/nKVkLvrk/622-error-handling-low
          }
        }
        populateContext();
      }
    }
  }, [reportId, reportUserNotesAnswers.report, userInfo.organizationId]);
  
  //TODO: in the PR after custom hooks, replace the following by using useTranslator, https://trello.com/c/LgQugjiG/628-consistent-app-wide-translation
  const intl = useIntl();
  const translator = (translationId: string) => {
    return intl.formatMessage({ id: `report.${translationId}`, defaultMessage: translationId });
  }

  const incidentSectionKey = "s-incident";
  const locationOfIncidentAnswerKey = "q-whereAddress";
  const timeOfIncidentAnswerKey = "q-when";

  const reportSummaryFields = [
    {
      key: "locationOfIncident",
      value: reportUserNotesAnswers.answers?.get(getAnswerKey(incidentSectionKey, locationOfIncidentAnswerKey))?.valueFormatted,
      icon: <LocationIcon style={IconStyle.InLine} />
    },
    {
      key: "timeOfIncident",
      value: reportUserNotesAnswers.answers?.get(getAnswerKey(incidentSectionKey, timeOfIncidentAnswerKey))?.valueFormatted,
      icon: undefined
    }
  ];

  let basicReportInfo = Object.values(reportSummaryFields).map((reportField) => {
    return {
      key: reportField.key,
      value: reportField.value,
      icon: reportField.icon
    }
  });

  const reportFields = [
    ReportFields.AllowPoliceContact,
    ReportFields.AllowSupportCenterContact,
    ReportFields.AllowCampusContact
  ].map((reportField) => {

    if (isOrganizationTypeVesta(userInfo.organizationTypeId)) {
      return {
        key: reportField,
        value: convertBoolToYesNo( reportUserNotesAnswers.report?.[reportField] )
      }
    } else {
      return null;
    }

  });

  let contactDetails = []
  if (userInfo.viewReportContactDetails === PermissionScope.Organization || userInfo.viewReportContactDetails === PermissionScope.Assigned) {
    contactDetails.push(
      {
        key: 'Name',
        value: reportUserNotesAnswers.reportUser?.fullName,
        icon: undefined
      },
      {
        key: 'Phone Contact',
        value: reportUserNotesAnswers.reportUser?.phoneNumber,
        icon: undefined
      },
      {
        key: 'Email',
        value: reportUserNotesAnswers.reportUser?.email,
        icon: undefined
      })
  }
  let preferredFields: Array<fieldBlock> = [];
  if (userInfo.shareOrganization === PermissionScope.All) {
    preferredFields = [
      {
       key: 'Preferred Location for Support',
       value: reportUserNotesAnswers?.report?.supportLocation?.organizationName
      },
      {
        key: 'Preferred Campus for Support',
        value: reportUserNotesAnswers?.report?.campusSupportLocation?.organizationName
      }
    ]
  }

  const basicReportInfoList = [ ...basicReportInfo];

  const consentedFieldsInfoList = [ ...reportFields];
  const preferredFieldsInfoList = preferredFields;
  const contactInfoFields = [ ...contactDetails];

  //TODO: if police logged in only show police report fields and same for crisis centre, https://trello.com/c/SjjPNQzR/629-only-show-respective-report-fields-for-respective-role-police-vs-crisis-staff
  return (<>
    <div className={styles.basicInfo}>
      <div className={classNames(styles.basicReportInfo, styles.redBox)}>
      {basicReportInfoList.map((entity) => {
          return (
            entity &&
            <div className={classNames(styles.basicReportInfoField, styles.withIcon)} key={entity?.key}>
              <div className={styles.iconRow}>{entity.icon ? entity.icon : null}
              </div>
              <div>
              <h5 className={styles.label}>{translator(entity ? entity.key : '')}</h5>
              <p className={styles.value} >{entity?.value ? entity.value : intl.formatMessage({id: 'common.na', defaultMessage:'n/a'})}</p>
              </div>
            </div> )
        })}
      </div>
      {contactInfoFields.length > 0 && <div className={classNames(styles.fullBasicReportInfo)}>
        {contactInfoFields.map((entity) => {
          return (
            entity &&
            <div className={styles.basicReportInfoField} key={entity?.key}>
              <h5 className={styles.label}>{translator(entity ? entity.key : '')}</h5>
              <p className={styles.value} >{entity?.value ? entity.value : intl.formatMessage({id: 'common.na', defaultMessage:'n/a'})}</p>
            </div> )
        })}
      </div>}
      <div className={classNames(styles.fullBasicReportInfo)}>
        {consentedFieldsInfoList.map((entity) => {
          return (
            entity &&
            <div className={styles.basicReportInfoField} key={entity?.key}>
              <h5 className={styles.label}>{translator(entity ? entity.key : '')}</h5>
              <p className={styles.value} >{entity?.value ? entity.value : intl.formatMessage({id: 'common.na', defaultMessage:'n/a'})}</p>
            </div> )
        })}
      </div>
      <div className={styles.fullBasicReportInfo}>
        {preferredFieldsInfoList.map((entity) => {
          return (
            entity &&
            <div className={classNames(styles.basicReportInfoField, styles.withIcon)} key={entity?.key}>
             <div  className={styles.iconRow}><LocationGrayIcon style={IconStyle.InLine} /> </div>
             <div><h5 className={styles.label}>{translator(entity ? entity.key : '')}</h5>
              <p className={styles.value} >{entity?.value ? entity.value : intl.formatMessage({id: 'common.na', defaultMessage:'n/a'})}</p>
              </div>
            </div> )
        })}
      </div>
      
    </div>
    {(userInfo.shareOrganization === PermissionScope.All) && (<div className={styles.basicInfo}>
      <div className={styles.basicReportInfoField}>
              <h5 className={styles.label}>{intl.formatMessage({ id: `report.followedIntakeFormLink`, defaultMessage: `Followed Intake Form link!` })}</h5>
              <div className={classNames(styles.withIcon)}>
                <div className={styles.iconRow}>
                <ExclamationGrayIcon style={IconStyle.InLine} />
                </div>
                <div className={styles.explainer}>
                  <p>
                    {intl.formatMessage({ id: `report.followedIntakeFormLinkExplanationLine1`, defaultMessage: `This means the survivor clicked into an intake form for their preferred support location.` })}
                  </p>
                  <p>
                  {intl.formatMessage({ id: `report.followedIntakeFormLinkExplanationLine2`, defaultMessage: `This does not necessarily mean the survivor filled out the external form.` })}
                  </p>
                </div>
              </div>
              <p className={styles.value} >
                {reportUserNotesAnswers?.report?.followIntakeFormSupportLocationId ? intl.formatMessage({id: 'common.yes', defaultMessage:'yes'}) :  intl.formatMessage({id: 'common.no', defaultMessage:'no'}) }
                { reportUserNotesAnswers?.report?.followIntakeFormSupportLocationId === reportUserNotesAnswers?.report?.supportLocation?.id ? ` - ${reportUserNotesAnswers?.report?.supportLocation?.organizationName}` : null}
                </p>
      </div> 
    </div>)}
  </>);
}
